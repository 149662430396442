
:root {
    --default-color: #FF9922;
    --white: #FFFFFF;
    --gray: #29292B;
    --black: #080909;
    --wheel-border: #5F1317;
    --wheel-border-light: #842025;
    --wheel-border-dark: #3F1114;
    --wheel-border-darker: #2F0103;
    --button-color: #54A632;
    --win-color: #fff;
    --token-color: #FEE078;
    --bet-color: #9FFE78;
}

$white: var(--white);
$gray: var(--gray);
$black: var(--black);
$wheel-border: var(--wheel-border);
$wheel-border-light: var(--wheel-border-light);
$wheel-border-dark: var(--wheel-border-dark);
$wheel-border-darker: var(--wheel-border-darker);
$button-color: var(--button-color);
$win-color: var(--win-color);
$token-color: var(--token-color);
$bet-color: var(--bet-color);
$default-color: var(--default-color);

*, ::before, ::after {
    box-sizing: border-box;
    scroll-behavior: smooth;
    user-select: none;
}

body {
    font-family: 'Oswald', sans-serif;
    width: 100%;
    background-color: $black;
    margin: 0;
    max-width: 100%;
    max-height: 100vh;
    // overflow: hidden;
}

.table {
    width: 100%;
    height: 100vh;
    justify-content: space-around;
    display: flex;
    align-items: center;
    position: relative;
    background-color: #030303;
    flex-direction: column;
    background-image: url(https://wheel.asimthecat.com/images/eternalsunshineofthespotlessmind.png);
    background-size: 170%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.4);
    &::before {
        content: '';
        position: absolute;
        width: 90%;
        height: 40vh;
        box-shadow: 0px 50px 130px 0px $win-color;
        border-radius: 50%;
        top: -45vh;
        left: 0;
        right: 0;
        margin: auto;
        transition: .2s cubic-bezier(.19,1,.22,1) all;
    }
}

.winner-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(0);
    z-index: -1;
    opacity: 0;
    transition: .2s ease-in-out all;
    &.show {
        backdrop-filter: blur(3px);
        z-index: 999;
        opacity: 1;
    }
    .winner {
        height: auto;
        position: absolute;
        z-index: 999;
        aspect-ratio: 2;
        width: calc(60vh + 5vw);
        background: #181819;
        border: 1px solid #29292B;
        box-shadow: 0px 4px 35px 17px rgba(0, 0, 0, 0.53);
        border-radius: 26px;
        inset: 0;
        margin: auto;
        border: 4px solid $default-color;
        top: -15vh;
        max-width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .exit {
            position: absolute;
            right: 1vw;
            top: 1vw;
            display: flex;
            cursor: pointer;
            height: 32px;
            width: 32px;
            align-items: center;
            justify-content: center;
            &:before {
                content: "\00d7";
                color: #fff;
                font-size: calc(44px + 1vw);
            }
        }
        .header {
            font-weight: 700;
            font-size: calc(32px + 2vw);
            line-height: calc(40px + 2.4vw);
            color: #fff;
            text-align: center;
            text-transform: uppercase;
        }
        .content {
            font-weight: 600;
            font-size: calc(24px + 1vw);
            line-height: calc(32px + 2vw);
            color: #fff;
            text-align: center;
            text-transform: uppercase;
        }
        .footer {
            font-weight: 700;
            font-size: calc(40px + 2.4vw);
            line-height: calc(48px + 3vw);
            text-align: center;
            color: #fff;
            text-transform: uppercase;
        }
    }
}

.wheel {
    width: calc(50vh + 5vw);
    height: auto;
    aspect-ratio: 1;
    position: relative;
    max-width: 90%;
}
.lights {
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 50%;
    position: relative;
    justify-content: center;
    align-items: center;
    span {
        position: absolute;
        display: flex;
        background: $white;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        z-index: 9;
        animation: glow ease-in-out infinite 2s;
        transition: .2s ease-in-out all;
        background-color: $win-color;
    }
}

@keyframes glow {
    0% {
        box-shadow: 0px 0px 10px 5px $win-color;
    }
    50% {
        box-shadow: 0px 0px 10px 0px $win-color;
    }
    100% {
        box-shadow: 0px 0px 10px 5px $win-color;
    }
}

.border {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: 0px 8px 30px 2px $wheel-border;
    background: $wheel-border;
    border-radius: 50%;
    margin: auto;
    inset: 0;
}

.border-in {
    position: absolute;
    width: 92%;
    background: $wheel-border-dark;
    border-radius: 50%;
    margin: auto;
    inset: 0;
    aspect-ratio: 1;
}

.pie-out {
    position: absolute;
    width: 94%;
    background: linear-gradient(139.15deg, #1B1B1F 7.67%, #000001 106.82%), linear-gradient(139.15deg, #1B1B1F 7.67%, #000001 106.82%);
    margin: auto;
    inset: 0;
    border-radius: 50%;
    aspect-ratio: 1;
}

.pie {
    position: absolute;
    width: 97%;
    background: linear-gradient(318.69deg, #1B1B1F 13.96%, #000001 133.42%), linear-gradient(139.15deg, #1B1B1F 7.67%, #000001 106.82%);
    border-radius: 50%;
    margin: auto;
    inset: 0;
    border-radius: 50%;
    aspect-ratio: 1;
}

.pie svg {
    transition: 5s cubic-bezier(.19,1,.22,1) all;
    text {
        font-size: 4px;
    }
}

.center-out {
    position: absolute;
    width: 30%;
    background: #000000;
    margin: auto;
    inset: 0;
    border-radius: 50%;
    aspect-ratio: 1;

}
.center {
    position: absolute;
    width: 84%;
    background: $wheel-border;
    border: 4px solid $wheel-border-light;
    margin: auto;
    inset: 0;
    border-radius: 50%;
    aspect-ratio: 1;

}
.center-in {
    position: absolute;
    background: linear-gradient(153.43deg, $wheel-border 19.6%, $wheel-border-darker 77.11%);
    border: 1px solid $wheel-border-light;
    box-shadow: 0px 0px 20px 5px $wheel-border-darker;
    margin: auto;
    inset: 0;
    border-radius: 50%;
    width: 80%;
    aspect-ratio: 1;

}

.arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    position: absolute;
    width: 100%;
    z-index: 9;
    .arrow-container {
        width: 12%;
        position: relative;
        svg {
            position: absolute;
            inset: 0;
            margin: auto;
        }
    }
}
.spin-button {
    box-sizing: border-box;
    position: absolute;
    bottom: 10vh;
    background: $button-color;
    border: 1px solid $button-color;
    box-shadow: 0px 6px 15px $button-color;
    border-radius: 8px;
    font-weight: 700;
    font-size: calc(18px + 1vw);
    color: $white;
    padding: calc(0.4vw + 4px) calc(1vw + 24px);
    text-transform: uppercase;
    cursor: pointer;
    z-index: 1;
    &:disabled {
        box-shadow: none;
    }
}

.bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    height: fit-content;
    z-index: 0;
    .bottom-side {
        height: 13vh;
        width: 33.33%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: $gray;
        border-radius: 24px;
        margin: 0 2vw;
        .side-card {
            background: $black;
            border: 1px solid $gray;
            border-radius: 23px;
            width: 46%;
            height: 85%;
            padding: 8px 12px;
            position: relative;
            .title {
                font-family: 'Oswald';
                font-style: normal;
                font-weight: 500;
                font-size: calc(8px + 0.5vw);
                line-height: 28px;
                color: #5F5F63;
                text-transform: uppercase;
            }
            .value {
                color: #FFF;
                position: absolute;
                inset: 0;
                margin: auto;
                width: fit-content;
                height: fit-content;
                font-size: calc(18px + 0.7vw);
                font-weight: 800;
                text-shadow: 0px 2px 12px #FFF;
                &.token {
                    color: $token-color;
                }
                &.bet {
                    color: $bet-color;
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .table {
        height: 90vh;
    }
    .bottom {
        width: 95%;
        .bottom-side {
            width: 50%;
            border-radius: 0;
            margin: 0;
            height: 10vh;
            &:nth-child(1) {
                border-bottom-left-radius: 10px;
                border-top-left-radius: 10px;
            }
            &:nth-child(2) {
                border-bottom-right-radius: 10px;
                border-top-right-radius: 10px;
            }
            .side-card {
                border-radius: 10px;
                width: 48%;
                height: 89%;
                padding: 0px 8px;
            }
        }
    }
    .spin-button {
        bottom: 25vh;
    }
}